// 
// images.scss
// Extended from Bootstrap
//

// 
// Additional styles for theme
//

.img-cover{
  object-fit: cover;
}