//
// accordion.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides
//
.accordion .accordion-button{
  text-align: left;
}

//
// Additional style for theme
// 

// accordion collapse plus/minus icon
.accordion{
  &.accordion-icon{
    .accordion-header{
      position: relative;
      .accordion-button{
        background:transparent;
        color: $dark;
        font-size: inherit;
        border: none;
        padding-left: 1.7rem;
        &:after {
          content:"";
          background: $gray-800;
          width: 10px;
          height: 2px;
          display: block;
          position: absolute;
          top: 45%;
          left: 0;
          transform: translateY(-50%);
          transition: $transition-base;
          transform: rotateZ(0deg);
        }
        &:before {
          content:"";
          background: $gray-800;
          width: 10px;
          height: 2px;
          display: block;
          position: absolute;
          top: 45%;
          left: 0;
          transform: translateY(-50%);
          transition: $transition-base;
          transform: rotateZ(0deg);
        }
        &.collapsed:after {
          transform: rotateZ(90deg);
        }
        &.collapsed:before {
          transform: rotateZ(0deg);
        }
      }
    }
    .accordion-collapse{
      border: none;
    }
    .accordion-body {
      padding: 0.2rem 0.5rem 0.5rem 1.7rem;
    }
  }
}

// accordion line
.accordion{
  &.accordion-line{
    .accordion-header{
      position: relative;
      .accordion-button{
        background:transparent;
        color: $dark;
        font-size: inherit;
        border: none;
        &:after {
          content:"";
          background: $gray-300;
          width: 100%;
          height: 2px;
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: $transition-base;
        }
         &:before {
          content:"";
          background: $primary;
          width: 100%;
          height: 2px;
          display: block;
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          transition: $transition-base;
        }
        &.collapsed:before {
          width: 0;
        }
        &.collapsed:after {
          transform: rotate(180deg);
        }
      }
    }
    .accordion-collapse{
      border: none;
    }
  }
}

// accordion primary
.accordion{
  &.accordion-primary{
    .accordion-header{
      position: relative;
      margin-bottom: 5px;
      .accordion-button{
        background: $primary;
        color: $white; 
        font-size: inherit;
        border: none;
        &:after {
          background-image: escape-svg($accordion-button-active-icon-white);
        }

        &.collapsed {
          background: $gray-200;
          color: $dark;
          &:after {
            background-image: escape-svg($accordion-button-active-icon-dark);
          }
        }
      }
    }
    .accordion-collapse{
      border: none;
    }
  }
}