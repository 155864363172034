// 
// dropdowns.scss
// Extended from Bootstrap
// 

// 
// Bootstrap Overrides
//
.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}

.dropdown-item {
  font-weight: $dropdown-item-font-weight;
  text-transform: capitalize;
  position: relative;
}

.dropdown-item.active {
  color: $primary !important;
}

// Dropdown arrows
// Replace original arrows with Custom icons
// 
.dropdown-toggle {
  &:after {
    content: "\f078";
    font-family: 'Font Awesome 5 Free';
    font-size: 0.5rem;
    margin-left: 0.35rem;
    font-weight: 900;
    vertical-align: middle;
    border: none;
  }
}

// Dropdown overrides for navbar only
.dropdown-menu .dropdown-toggle {
  &:after {
    position: absolute;
    right: 25px;
    top: 10px
  }
  &:before {
    position: absolute;
    right: 25px;
    top: 10px
  }
}


// 
// Additional style for theme
// 
.dropdown-header {
  font-weight: $dropdown-header-font-weight;
  padding-top: $dropdown-padding-y - .5rem;
  font-size: $dropdown-header-font-size;
}

// Dropdown mega menu
.dropdown-fullwidth {
  position: static;
  .dropdown-menu {
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 1rem 0;
  }
}

// Dropdown fullwidth menu width on responsive
@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint) {
    .dropdown-fullwidth {
      .dropdown-menu {
        max-width: $container-max-width; 
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .dropdown-fullwidth .dropdown-menu .container{
    max-width: 100%;
  }
}


// Dropdown menu open on hover after xl
@include media-breakpoint-up(xl) {
  .navbar-expand-xl {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: $transition-base;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .navbar-expand-xl .dropdown-submenu {
    position: relative;
  }
}


// Dropdown menu open on hover after lg
@include media-breakpoint-up(lg) {
  .navbar-expand-lg {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: $transition-base;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .navbar-expand-lg .dropdown-submenu {
    position: relative;
  }
}


// Dropdown menu open on hover after md
@include media-breakpoint-up(md) {
  .navbar-expand-md {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: $transition-base;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .navbar-expand-md .dropdown-submenu {
    position: relative;
  }
}

// Dropdown menu open on hover after sm
@include media-breakpoint-up(sm) {
  .navbar-expand-sm {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: $transition-base;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .navbar-expand-sm .dropdown-submenu {
    position: relative;
  }
}